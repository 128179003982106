<template>
<div id="company">

</div>
</template>

<script>
export default {
  name: "company",
  data() {
    return {

    }
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

</style>
